import React from "react";
import {BulbOutlined, LikeOutlined} from "@ant-design/icons";
import {Card, Col, Row} from "antd";
import SmartItem from "./SmartItem";
import {Type} from "./data/model";

const SectionHeader = ({item, header, index, congrats, readonly, onChange, actionsFn, children}) => {

    const congratsTitle = congrats() ? <LikeOutlined className="light-button"/> : "";

    const title =
        <Row justify="space-around" align="middle">
            <Col span={23}>
                {congratsTitle} {item.type === Type.SPECIAL ? <BulbOutlined/> : <></>} <SmartItem value={header}
                                           type="header"
                                           readonly={readonly}
                                           index={index}
                                           onChangeItem={onChange}
                                           actionsFn={actionsFn}
            />
            </Col>
            <Col span={1}>

            </Col>
        </Row>

    return (
        <>
            <Card hoverable={false} title={title}>{children}</Card>
        </>
    );
};

export default SectionHeader;
