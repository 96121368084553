import React, {useState} from "react";
import SmartItem from "../../SmartItem";
import {getTextByEnergyLevel} from "../../data/parameters";
import {getLevel} from "../../EnergyLevelWidget";


const QuestionText = ({data, index, readonly, onChange, actionsFn, energyLevel, adjusted, adjustAvailable}) => {

    const [label, setLabel] = useState(data.label);

    const onChangeLabel = (newLabel) => {
        setLabel(newLabel);
        const newData = data;
        newData.label = newLabel;
        onChange(newData, index);
    }

    function makeLabel(text) {
        //console.log('makeLabel', text, energyLevel, adjusted)
        return adjusted ? getTextByEnergyLevel(text, getLevel(energyLevel)) : getTextByEnergyLevel(text, 'medium');
    }

    return (
        <>
            <SmartItem value={label}
                       type="item"
                       readonly={readonly}
                       index={index}
                       onChangeItem={onChangeLabel}
                       actionsFn={actionsFn}
                       transformFn={t => makeLabel(t)}
                       adjustAvailable={adjustAvailable}
            />
        </>
    )
};


export default QuestionText;