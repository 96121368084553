import React, {useState} from "react";
import withAuthorization from "./utils/withAuthorization";
import {generateInvitation, Permissions} from "./data/permissions";
import {Button, Col, Input, Modal, Radio, Row, Typography} from "antd";
import MessageSender from "./MessageSender";

const {Text} = Typography;

const Invite = ({user}) => {
    const [email, setEmail] = useState("");
    const [general, setGeneral] = useState(true);
    const [generatedToken, setGeneratedToken] = useState();
    const [error, setError] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setGeneral(true)
        setEmail("")
        setError(undefined)
        setGeneratedToken(undefined)
        setIsModalOpen(false);
    };

    const onMultiUse = (e) => {
        setGeneral(true)
        setEmail("")
        setError(undefined)
        setGeneratedToken(undefined)
    }

    const onPersonal = (e) => {
        setGeneral(false)
        setEmail("")
        setError(undefined)
        setGeneratedToken(undefined)
    }


    const onGenerate = async () => {
        const token = await generateInvitation(user, general, email)
        if (token !== undefined) {
            setGeneratedToken(token);
        } else {
            setError("An error occurred in attempt to generate an invitation. PLease contact CAPS customer support")
        }

    };


    return (
        <>
            <Button type="link" style={{marginLeft: "-1em"}} onClick={showModal}>Generate Invitation Token</Button>
            <Modal title="Generate Invitation Token" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                   footer={[]}>
                <Row justify="space-around" align="top" style={{height: "2em"}}>
                    <Col span={24}>
                        <Radio defaultChecked={general} checked={general} onClick={onMultiUse}>
                            Multi-use
                        </Radio>
                    </Col>
                </Row>
                <Row justify="space-around" align="top" style={{height: "2em"}}>
                    <Col span={24}>
                        <Radio defaultChecked={!general} checked={!general} onClick={onPersonal}>
                            Personal
                        </Radio>
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email"
                               disabled={general}
                               style={{marginLeft: "1em", width: "15em"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button type="primary" onClick={onGenerate}>
                            Generate
                        </Button>
                    </Col>
                </Row>
                {generatedToken && <Row style={{marginTop: "1em"}}>
                    <Col span={24}>
                        <MessageSender initialText={generatedToken}/>
                    </Col>
                </Row>}
                {error && <Row style={{marginTop: "1em"}}>
                    <Col span={24}>
                        <Text className="error">{error}</Text>
                    </Col>
                </Row>}
            </Modal>
        </>
    );
};

export default withAuthorization(Invite, [Permissions.PM_INVITE_OTHERS], <span style={{marginTop: "1em"}}>Invite others (no permission)</span>);




