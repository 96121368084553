import {createContext, useContext, useEffect, useState} from "react";
import {GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut} from "firebase/auth";

import {auth, firestore} from "../firebase-config";

import {collection, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import moment from "moment/moment";
import {retrieveUserWithPermissions} from "../components/data/permissions";
import {createProfile} from "../components/data/model";


const userAuthContext = createContext();

export function UserAuthContextProvider({children}) {
    const [user, setUser] = useState({});
    const [tokenRequired, setTokenRequired] = useState(false);
    const [error, setError] = useState();
    const [tmpUser, setTmpUser] = useState(undefined);

    function logOut() {
        setTmpUser(undefined);
        setTokenRequired(false);
        return signOut(auth);
    }

    function googleSignIn() {
        const googleAuthProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleAuthProvider)
    }

    async function tokenVerification(token) {
        console.log('tokenVerification', token)
        const invitations = collection(firestore, "invitations")
        const q = query(invitations, where("token", "==", token))
        try {
            const snapshot = await getDocs(q);
            if (snapshot.empty) {
                // No matching invitation found, sign out the user
                setTokenRequired(true);
                setError("No invitation found by token");
            } else {
                // update the invitation status
                console.log("Invitation found, will check its state");
                snapshot.forEach(async (docSnapshot) => {
                    const inv = docSnapshot.data();
                    console.log('Invitation by token: ', inv);
                    const invitationDocRef = doc(firestore, 'invitations', docSnapshot.id)
                    if (inv.email === tmpUser.email) {
                        await updateDoc(invitationDocRef, {
                            status: 'accepted',
                            updated: moment.utc().format(),
                        });
                    } else {
                        await updateDoc(invitationDocRef, {
                            updated: moment.utc().format(),
                            invitees: (inv.invitees ? inv.invitees : 0) + 1
                        });
                    }
                    createProfile(tmpUser, inv.referral)
                    setTokenRequired(false);
                    setTmpUser(null);
                    setError(undefined);
                    setUser(tmpUser);
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
            console.log("Auth", currentuser);
            if (currentuser) {
                const profile = await retrieveUserWithPermissions(currentuser)
                if (profile === undefined) {
                    setTmpUser(currentuser)
                    setTokenRequired(true)
                } else {
                    const userWithPermissions = currentuser;
                    userWithPermissions.permissions = profile.permissions;
                    setUser(userWithPermissions)
                }
            } else {
                console.log('User is not authorized')
                setTmpUser(null);
                setUser(null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <userAuthContext.Provider
            value={{
                user, logOut, googleSignIn,
                tokenRequired, tokenVerification, error, tmpUser
            }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}