import React, {useState} from "react";
import {ReactComponent as LogoSvg} from '../resources/logo.svg';
import {DownOutlined, UserOutlined,} from '@ant-design/icons';
import {Button, Col, Dropdown, Modal, Row, Switch, Tag} from "antd";
import {useUserAuth} from "../context/UserAuthContext";
import {useNavigate} from "react-router";
import moment from "moment/moment";
import Onboarding from "./Onboarding";
import Invite from "./Invite";
import {updateThemeInProfile} from "./data/model";


export default function AppHeader({hideUser, profile, onReplaceTodaysEntry}) {
    const {logOut, user} = useUserAuth();
    const navigate = useNavigate();
    const [theme, setTheme] = useState(profile ? profile.theme : 'light');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onChange = (checked) => {
        const newTheme = checked ? "dark" : "light"
        setTheme(newTheme)
        updateThemeInProfile(user, newTheme)
    };

    const items = [
        {
            key: '1',
            label: <><Tag color="orange">Coming soon!</Tag>Light&nbsp;<Switch style={{marginBottom: "0.1em"}}
                                                                              size="small"
                                                                              defaultChecked={theme === 'dark'}
                                                                              onChange={onChange}/>&nbsp;Dark</>
        },
        {
            key: '2',
            label: (
                <Button type="link" onClick={handleLogout} style={{marginLeft: "-1em"}}>
                    Log out
                </Button>
            )
        },
        {
            key: '3',
            label: (
                <Button type="link" onClick={showModal} style={{marginLeft: "-1em"}}>
                    Onboarding
                </Button>
            )
        },
        {
            key: '5',
            label: (
                <><Invite user={user}/></>
            )
        },
        {
            key: '6',
            label: (
                <><Tag color="default">Profile
                    created: {profile ? moment(profile.created).format("MMM,DD HH:mm") : ""}</Tag></>
            )
        }
    ]


    return (
        <div id="header">
            <Row justify="space-around" align="top" style={{height: "4.5em", backgroundColor: "#e65728"}}>
                <Col span={5}>
                    <LogoSvg style={{height: "10em", width: "10em", marginTop: "-3.0em", marginLeft: "-1.0em"}}/>
                </Col>
                <Col span={16}>
                    <Modal title="Onboarding" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}
                           className="onboarding">
                        <Onboarding dialogMode={true} onFinish={handleOk} onReplace={onReplaceTodaysEntry}/>
                    </Modal>
                </Col>
                <Col span={3}>
                    {!hideUser &&
                        <Dropdown menu={{items}} placement="bottomRight" trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()}>
                                <UserOutlined
                                    style={{fontSize: '1.5em', color: 'white', marginTop: "1.0em"}}/>
                                <DownOutlined style={{fontSize: '1.0em', color: 'white'}}/>
                            </a>
                        </Dropdown>
                    }
                </Col>
            </Row>
        </div>
    )
}

