import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {UserAuthContextProvider} from "./context/UserAuthContext";
import Agreement from "./components/Agreement";
import OnboardingPage from "./components/OnboardingPage";

function App() {
    return (
        <UserAuthContextProvider>
            <Router>
                <div>
                    <section>
                        <Routes>
                            <Route path="/home" element={<Home/>}/>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/agreement" element={<Agreement/>}/>
                            <Route path="/onboarding" element={<OnboardingPage/>}/>
                            {/*<Route path="/signup" element={<Signup />} />*/}
                        </Routes>
                    </section>
                </div>
            </Router>
        </UserAuthContextProvider>
    );
}

export default App;
