import moment from "moment/moment";
import {get, getDatabase, limitToLast, orderByKey, query, ref, set} from "firebase/database";
import {generateSecureId} from "./permissions";
import {parseToDo} from "../widgets/todo/ToDo";
import {parseQuestion, Status} from "../widgets/question/Questions";
import React from "react";

const md2json = require("md-2-json");

export const Type = {
    TODO: 'TODO',
    QUESTIONS: 'QUESTIONS',
    FREEFORM: 'FREEFORM',
    SPECIAL: 'SPECIAL',
    NOTE: 'NOTE',
};

export const Focus = {
    TRADING: 'TRADING',
    GENERAL: 'GENERAL'
};

export function parseDataIntoModel(entry) {
    if (entry.v1) {
        return entry.v1;      //array [{id:,type:,raw:,}]
    }
    let text = entry.data
    //console.log('parseDataIntoModel: ' + text);
    if (text === undefined || text.length === 0) {
        return [];
    }
    const widgetsData = [];
    try {
        if (text.indexOf("\n") === -1) {
            text = text.replaceAll("*", "\n*").replaceAll("- [", "\n- [").replaceAll("# QUESTIONS", "\n\n\n# QUESTIONS")
        }
        let parsed = md2json.parse(text);
        let i = 0;
        for (const [key, value] of Object.entries(parsed)) {
            //console.log(key)
            //console.log(value)
            if (value !== undefined && value.raw !== undefined) {
                widgetsData.push({
                    raw: value.raw.trim(),
                    header: key.replace("QUESTIONS:", ""),
                    index: i,
                    type: detectType(key, value.raw),
                })
            }
            i++;
        }
        return widgetsData.map(wd => {
            const data = wd;
            data.id = generateSecureId(10);
            data.list = parseList(wd.raw).map(x => {
                let idx = x;
                if (wd.type === Type.TODO) {
                    idx = parseToDo(x);
                } else if (wd.type === Type.QUESTIONS) {
                    idx = parseQuestion(x)
                }
                idx.id = generateSecureId(10);
                return idx;
            });
            delete data.raw;
            return data;
        });
    } catch (e) {
        console.log(e);
        return [];
    }

}

export function detectType(header, text) {
    if (header && header.indexOf("QUESTIONS:") > -1) {
        return Type.QUESTIONS
    }
    if (text && text.startsWith("- [")) {
        return Type.TODO
    }
    return Type.FREEFORM
}

function convertModelToMd(model) {                // model = [{header,index,text}]
    //console.log(convertModelToMd)
    //console.log(model)
    const tmp = {}
    model.map(it => {
        tmp[it.header] = {
            raw: it.raw + "\n\n "
        }
    })
    //console.log(tmp)
    return md2json.toMd(tmp)
}

/*
export function getStatusFromText(text) {
    const done = (text.match(/[X]/g) || []).length;
    const skipped = (text.match(/- \[ \]/g) || []).length;
    const yes = (text.match(/:YES/g) || []).length;
    const no = (text.match(/:NO/g) || []).length;
    const empty = (text.match(/:EMPTY/g) || []).length;
    return {
        done: done + yes,
        missed: skipped + no + empty,
        total: done + skipped + yes + no + empty
    }
}
*/

export function getStatusFromText(entry) {
    const data = parseDataIntoModel(entry);
    let done = 0;
    let skipped = 0;
    let yes = 0;
    let no = 0;
    let empty = 0;
    data.map(x => {
        if (x.type === Type.TODO || x.type === Type.SPECIAL) {
            x.list.map(y => {
                if (y.done) {
                    done++;
                } else {
                    skipped++;
                }
            })
        } else if (x.type === Type.QUESTIONS) {
            x.list.map(y => {
                if (y.status === Status.YES) {
                    yes++;
                } else if (y.status === Status.NO) {
                    no++;
                } else {
                    empty++;
                }
            })
        }
    })

    return {
        done: done + yes,
        missed: skipped + no + empty,
        total: done + skipped + yes + no + empty
    }
}


export function createTodayJournalFromMostRecentEntry(recentEntryValue, user) {
    console.log("Recent journal retrieved");
    console.log("Will create a new one from the one retrieved");
    console.log(recentEntryValue);
    if (recentEntryValue["data"] === undefined) {
        console.error("An error occurred in attempt to create new journal from the following entry:", recentEntryValue)
    }
    const result = createTodayJournalFromTemplateEntry(user);


    let clearedModel = [];
    let model = parseDataIntoModel(recentEntryValue);

    //console.log('parseDataIntoModel', model)

    if (model.length > 0) {
        model.forEach((block) => {
            if (block.type !== Type.SPECIAL) {
                const newBlock = {
                    id: generateSecureId(10),
                    type: block.type,
                    header: block.header.replace("QUESTIONS:", "")
                }
                if (block.type === Type.TODO) {
                    //block.raw = block.raw.replaceAll("- [X]", "- [ ]")
                    newBlock.list = block.list.map((x) => {
                        return {
                            done: false,
                            label: x.label,
                            id: generateSecureId(10),
                        };
                    });
                } else if (block.type === Type.QUESTIONS) {
                    /*let questions = block.raw.toString().split("\n")
                    let clearedQuestions = [];
                    if (questions.length > 0) {
                        questions.forEach(q => {
                            let i = q.lastIndexOf(":");
                            if (i === -1) {
                                i = q.length - 1;
                            }
                            clearedQuestions.push(q.substring(0, i) + ":EMPTY")
                        })
                    }
                    block.raw = clearedQuestions.join("\n");*/
                    newBlock.list = block.list.map((x) => {
                        return {
                            status: "EMPTY",
                            label: x.label,
                            id: generateSecureId(10),
                        };
                    });
                }
                clearedModel.push(newBlock);
            }
        })
    }
    result.v1 = clearedModel;
    //result["data"] = convertModelToMd(clearedModel)
    return result;
}

export function createTodayJournalFromTemplateEntry(user, isTrader) {
    console.log('createTodayJournalFromTemplateEntry', user)
    const todayInDbFormat = moment().format('YYYYMMDD');
    const todayJournalUid = user.uid + ":" + todayInDbFormat;
    return {
        journalUid: todayJournalUid,
        date: todayInDbFormat,
        userUid: user.uid,
        userName: user.displayName,
        energyLevel: 0,
        todayIs: "",
        v1: [
            {
                "id": generateSecureId(10),
                "header": "Before Everything Else",
                "type": Type.TODO,
                "list": [
                    {
                        "done": false,
                        "id": generateSecureId(10),
                        "label": "Keep calm and go on!"
                    },
                    {
                        "done": false,
                        "id": generateSecureId(10),
                        "label": "Describe your day in one sentence"
                    }
                ]
            },
            {
                "id": generateSecureId(10),
                "header": "To ask yourself",
                "type": Type.QUESTIONS,
                "list": [
                    {
                        "status": Status.EMPTY,
                        "id": generateSecureId(10),
                        "label": isTrader ? "Lost less than [4,5,6] trades during the day?" : "Read a book for at least [15,30,60] minutes"
                    }
                ]
            },
            {
                "id": generateSecureId(10),
                "header": "Today's special",
                "type": Type.SPECIAL,
                "list": [
                    {
                        "done": false,
                        "id": generateSecureId(10),
                        "label": "🎦 Watch CAPS tutorial!"
                    }
                ]
            }
        ]
    };
}

export function saveToDb(journal) {
    const db = getDatabase();
    const journalsRef = ref(db, 'journals/' + journal.userUid + "/" + journal.date);
    //todo - add error/success processing
    set(journalsRef, journal);
}

export async function retrieveDbData(todayInDbFormat, user) {
    console.log('retrieveDbData')
    console.log('todayInDbFormat:', todayInDbFormat, user)
    const db = getDatabase();
    //get 20 recent entries
    const q = query(ref(db, 'journals/' + user.uid), orderByKey(), limitToLast(20));
    //the latest - either a today's entry or the most recent which should be used for the 'recent' tab, all other are history

    let tm;  //today model
    let rm;  //recent model
    let hms = [];
    await get(q).then((snapshot) => {
        //history models
        if (snapshot.exists()) {
            for (const [key, value] of Object.entries(snapshot.val())) {
                if (key === todayInDbFormat) {
                    console.log("Today's journal is found")
                    tm = value;
                } else {
                    hms.push(value);
                }
            }
            if (tm !== undefined) {
                if (tm.v1 === undefined) {
                    tm.v1 = parseDataIntoModel(tm);
                    saveToDb(tm);
                }
                rm = hms[hms.length - 1];
            } else if (hms.length > 0) {
                rm = hms[hms.length - 1];
                tm = createTodayJournalFromMostRecentEntry(rm, user)
                console.log('createTodayJournalFromMostRecentEntry', tm)
                saveToDb(tm)
            } else {
                tm = createTodayJournalFromTemplateEntry(user)
                saveToDb(tm)
            }
        } else {
            console.log("Journal is not found, will create completely new one");
            tm = createTodayJournalFromTemplateEntry(user)
        }
        //console.log('today')
        //console.log(tm)
        //console.log('recent')
        //console.log(rm)
        //console.log('history')
        //console.log(hms)
    }).catch((error) => {
        console.error(error);
    });

    return {
        today: tm,
        recent: rm,
        history: hms
    }
}

export function parseList(text) {
    if (text === undefined) {
        return [];
    }
    try {
        return text.toString().split("\n");
    } catch (e) {
        console.log(e)
        console.log(text)
        return [];
    }
}

export function parseHeader(text, prefix) {
    if (text === undefined) {
        return "";
    }
    try {
        return text.substring(prefix.length)
    } catch (e) {
        console.log(e)
        console.log(text)
        return "";
    }
}

export async function createProfile(user, referral) {
    const profile = {
        email: user.email,
        theme: "light",
        created: moment().toString(),
        referral: referral,
    };
    const db = getDatabase();
    const q = query(ref(db, 'users/' + user.uid), orderByKey(), limitToLast(1));  // profile
    set(q, {profile: profile})
    return profile;
}


export async function updateProfile(user, newProfile) {
    const db = getDatabase();
    const q = query(ref(db, 'users/' + user.uid), orderByKey(), limitToLast(1));  // profile
    set(q, {profile: newProfile})
    return newProfile;
}

export async function updateThemeInProfile(user, newTheme) {
    const db = getDatabase();
    const q = query(ref(db, 'users/' + user.uid + '/profile/theme'), orderByKey(), limitToLast(1));  // profile
    set(q, newTheme)
}

export const CURRENT_LA_VERSION = "1.0"

export async function acceptLicenseAgreement(user) {
    const db = getDatabase();
    const q = query(ref(db, 'users/' + user.uid + '/profile/laVersion'), orderByKey(), limitToLast(1));  // profile
    set(q, CURRENT_LA_VERSION)
}

export async function finishOnboarding(user, isTrader) {
    const db = getDatabase();
    const q = query(ref(db, 'users/' + user.uid + '/profile/onboarding'), orderByKey(), limitToLast(1));  // profile
    set(q, CURRENT_LA_VERSION)
    const qt = query(ref(db, 'users/' + user.uid + '/profile/focus'), orderByKey(), limitToLast(1));  // profile
    set(qt, isTrader ? Focus.TRADING : Focus.GENERAL)
}


export async function getProfile(user) {

    const db = getDatabase();
    const profileRef = ref(db, 'users/' + user.uid);
    return await get(profileRef).then((snapshot) => {
        if (snapshot.exists()) {
            console.log('found profile', snapshot.val().profile)
            return snapshot.val().profile;
        } else {
            return undefined;
        }
    }).catch((error) => {
        console.error(error);
    });
}

export function getFlatList(data) {
    const model = parseDataIntoModel(data);
    if (model === undefined || model.length === 0) {
        return []
    }

    const result = []
    model.map(x => {
        if (x.type === Type.TODO) {
            x.list.map(i => {
                result.push(i.done ? 1 : -1)
            })
        }
        if (x.type === Type.QUESTIONS) {
            x.list.map(i => {
                result.push(i.status === Status.YES ? 1 : -1)
            })
        }
    })
    model.map(x => {
        if (x.type === Type.SPECIAL) {
            result.push(0)
            x.list.map(i => {
                result.push(i.done ? 1 : -1)
            })
        }
    })
    return result;
}

export function getSparkline(data) {
    return data.map(x => x === 1 ? <span style={{fontSize: "xx-small", color: "#B8C8B8"}}>●</span> : x === -1 ?
        <span style={{fontSize: "xx-small", color: "#DCAF96"}}>○</span> :
        <span style={{fontSize: "xx-small", color: "darkgray"}}>-</span>)
}
