import React, {useRef, useState} from 'react';
import {Button, Modal, Typography} from 'antd';

import Timer from "./Timer";

const {Title} = Typography;

export default function SosButton() {
    const [visible, setVisible] = useState(false);
    const pressTimer = useRef();

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleButtonPress = () => {
        showModal();
        /*pressTimer.current = window.setTimeout(() => {
        }, 120000); // 2 minutes*/
    };

    const handleButtonRelease = () => {
        /* clearTimeout(pressTimer.current);*/
        handleCancel();
    };

    return (
        <div>
            <Button
                onMouseDown={handleButtonPress}
                onMouseUp={handleButtonRelease}
                onTouchStart={handleButtonPress}
                onTouchEnd={handleButtonRelease}
                className="sos-button"
            >
                SOS
            </Button>
            <Modal
                open={visible}
                onCancel={handleCancel}
                footer={null}
                closable={false}
                className="sos-dialog"
                destroyOnClose={true}
            >
                <div className="sos-content rainbow">
                    <div style={{display: "block"}}>
                        <Title level={1}>Breath...</Title>
                    </div>
                    <div style={{display: "block"}}>
                        <Timer></Timer>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
