import React, {useState} from "react";
import {Select} from "antd";


const QuestionAnswer = ({data, index, readonly, onChange}) => {

    const [status, setStatus] = useState(data.status);


    const onChangeStatus = value => {
        setStatus(value);
        const newData = data;
        newData.status = value;
        onChange(newData, index);
    }

    return (
        <Select
            defaultValue={status}
            onChange={onChangeStatus}
            disabled={readonly}
            options={[
                {value: 'EMPTY', label: 'Yes/No'},
                {value: 'YES', label: 'Yes'},
                {value: 'NO', label: 'No'},
            ]}
        />
    )
};


export default QuestionAnswer;