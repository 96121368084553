import React, {useState} from "react";
import {Col, Row} from "antd";
import List from "../../utils/List";
import SectionHeader from "../../SectionHeader";
import {AimOutlined, PlusOutlined} from "@ant-design/icons";
import QuestionText from "./QuestionText";
import QuestionAnswer from "./QuestionAnswer";

const prefix = "QUESTIONS:";

export const Status = {
    YES: 'YES',
    NO: 'NO',
    EMPTY: 'EMPTY'
};

export function getTextByStatus(status) {
    if (status === Status.YES) {
        return 'YES';
    }
    if (status === Status.NO) {
        return 'NO';
    }
    return 'EMPTY';
}


export function parseQuestion(text) {
    if (text === undefined) {
        return undefined;
    }
    const question = {};
    let i = text.lastIndexOf(":YES");
    let j = text.lastIndexOf(":NO");
    let k = text.lastIndexOf(":EMPTY");
    let z = i > -1 ? i : j > -1 ? j : k > -1 ? k : text.length - 1;
    question.status = i > -1 ? Status.YES : j > -1 ? Status.NO : Status.EMPTY;
    question.label = text.substring(1, z);
    return question;
}

export function toText(q) {
    return `* ${q.label}:${getTextByStatus(q.status)}`
}

export default function Questions({
                                      item,
                                      index,
                                      onChange,
                                      onChangeHeader,
                                      readonly,
                                      actionFn,
                                      energyLevel,
                                      adjusted,
                                      adjustAvailable
                                  }) {

    const [questions, setQuestions] = useState(item.list)
    const [header, setHeader] = useState(item.header)


    const onChangeH = text => {
        setHeader(text);
        onChangeHeader(item, text, index);
    }


    function ensureAllDone() {
        let done = 0;
        questions.forEach(function (it, index) {
            if (it.status === Status.YES) {
                done++;
            }
        });
        return done === questions.length && questions.length > 0
    }

    function createQuestion() {
        return {
            label: "Add questions",
            status: "EMPTY"
        };
    }

    function renderItem(data, index, actionsFn, readonly) {
        return <Row justify="start" align="middle" gutter={[8, 8]}>
            <Col span={2}>
                <AimOutlined/>
            </Col>
            <Col span={16}>
                <QuestionText data={data} index={index} readonly={readonly} onChange={onChangeSingleQuestion}
                              actionsFn={actionsFn}
                              energyLevel={energyLevel}
                              adjusted={adjusted}
                              adjustAvailable={adjustAvailable}
                > </QuestionText>
            </Col>
            <Col span={6}>
                <QuestionAnswer readonly={readonly} data={data} index={index} onChange={onChangeSingleQuestion}/>
            </Col>
        </Row>
    }

    function onChangeQuestion(list) {
        onChange(item, list, index);
    }

    function addItemButton(list, addItem) {
        if (readonly) {
            return
        }
        return <PlusOutlined className="light-button top-right" onClick={() => addItem(createQuestion)}/>
    }

    function onChangeSingleQuestion(data, index) {
        const newQuestions = questions.slice()
        newQuestions[index] = data;
        setQuestions(newQuestions)
        onChangeQuestion(newQuestions);
    }

    return (
        <div className="widget todo">
            <SectionHeader readonly={readonly} onChange={onChangeH} index={index} item={item} header={header}
                           congrats={ensureAllDone} actionsFn={actionFn}>
                <List items={questions}
                      render={renderItem} congrats={ensureAllDone}
                      readonly={readonly} onChange={onChangeQuestion} operations={addItemButton}></List>
            </SectionHeader>
        </div>
    )
}



