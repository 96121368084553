import React from 'react';

const ListItem = ({data, index, render, readonly, actionsFn}) => {
    return (
        <>
            {render(data, index, actionsFn, readonly)}
        </>
    );
};

export default ListItem;
