import {Navigate, useNavigate} from "react-router-dom";
import React from "react";
import AppHeader from "./AppHeader";
import {useUserAuth} from "../context/UserAuthContext";
import {Button, Space, Typography} from 'antd';
import {acceptLicenseAgreement, getProfile} from "./data/model";

const {Title} = Typography;


const Agreement = () => {
    const {logOut, user} = useUserAuth();
    const navigate = useNavigate();


    if (!user || !user.displayName) {
        return <Navigate to="/"/>;
    }

    async function onAgree() {
        await acceptLicenseAgreement(user)
            .catch(e => console.error(e))
        const profile = await getProfile(user)
        if (!profile.onboarding) {
            navigate("/onboarding");
        } else {
            navigate("/home");
        }
    }

    return (
        <>
            <AppHeader hideUser={true}/>
            <div className="main" style={{padding: "1em"}}>
                <Title level={3}>Terms of Services & Privacy</Title>
                <Title level={5} style={{fontWeight: "normal"}}>
                    The CAPS application is provided on an as-is and as-available basis by Mark
                    Shlyakhovetskyy.
                </Title>
                <Title level={5} style={{fontWeight: "normal"}}>
                    Mark Shlyakhovetskyy makes no representations or warranties of any kind, express or
                    implied, as to the operation of the CAPS application, or the information, content,
                    materials, or products included in it. You expressly agree that your use of the CAPS-Journal
                    application is at your sole risk.
                </Title>
                <Title level={5} style={{fontWeight: "normal"}}>
                    Mark Shlyakhovetskyy does not guarantee that the CAPS application will be uninterrupted,
                    error-free, or completely secure. Mark Shlyakhovetskyy does not guarantee that any defects or errors
                    will be corrected, or that the CAPS application will meet your expectations or requirements.
                    You acknowledge that Mark Shlyakhovetskyy is not responsible for any content, data, or information
                    that you upload or submit through the CAPS application, and you agree to use the
                    CAPS application at your own risk. You also acknowledge that Mark Shlyakhovetskyy is not
                    responsible for any delays, failures, or interruptions in the delivery of any content or services
                    provided through the Mark Shlyakhovetskyy, and you agree that Mark Shlyakhovetskyy will not be
                    liable for any damages arising from any such delays, failures, or interruptions.
                </Title>
                <Space>
                    <Button type="link" onClick={() => logOut()}>Cancel</Button><Button type="primary"
                                                                                        onClick={onAgree}>Agree &
                    proceed</Button>
                </Space>
            </div>
        </>
    );
};

export default Agreement;



