import React, {useState} from "react";
import {parseDataIntoModel, Type} from "./data/model";
import {Button, Space} from "antd";
import List from "./utils/List";
import Questions from "./widgets/question/Questions";
import ToDo from "./widgets/todo/ToDo";
import {generateSecureId} from "./data/permissions";
import {BulbOutlined, PlusOutlined} from '@ant-design/icons';

export default function Editor({onChange, energyLevel, readonly, adjusted, entry, adjustAvailable}) {

    const [model, setModel] = useState(parseDataIntoModel(entry));

    const hasSpecialWidget = model.filter(it => it.type === Type.SPECIAL).length > 0

    function createToDo() {
        return {
            header: (model.length === 0 ? "Before Everything Else" : "During the Day"),
            index: model.length,
            type: Type.TODO,
            id: generateSecureId(10),
            list: [{
                id: generateSecureId(10),
                done: false,
                label: "add something to do"
            }]
        }
    }

    function createQuestion() {
        return {
            header: "To ask yourself",
            index: model.length,
            type: Type.QUESTIONS,
            id: generateSecureId(10),
            list: [{
                id: generateSecureId(10),
                status: "EMPTY",
                label: "Made less than 5 trades during the day?"
            }]
        }
    }


    function createSpecial() {
        return {
            header: "Today\'s special",
            index: model.length,
            type: Type.SPECIAL,
            id: generateSecureId(10),
            list: [{
                id: generateSecureId(10),
                done: false,
                label: "Special action for today only"
            }]
        }
    }

    function changeItem(item, list, index) {
        console.log('changeItem', item, list, model)
        const newModel = model;
        newModel[index].list = list;
        setModel(newModel);
        onChange(newModel);
    }


    function changeHeader(item, header, index) {
        console.log('changeHeader', item, header, model)
        const newModel = model;
        newModel[index].header = header;
        setModel(newModel);
        onChange(newModel);
    }


    function isMoreWidgetsAllowed() {
        return model.length < 10;
    }

    function renderWidget(data, index, actionFn, readonly) {
        if (data.type === Type.TODO)
            return <ToDo item={data}
                         index={index}
                         key={data.id}
                         onChange={changeItem}
                         onChangeHeader={changeHeader}
                         actionFn={actionFn}
                         readonly={readonly}
            ></ToDo>

        if (data.type === Type.QUESTIONS)
            return <Questions item={data}
                              index={index}
                              key={data.id}
                              onChange={changeItem}
                              onChangeHeader={changeHeader}
                              energyLevel={energyLevel}
                              actionFn={actionFn}
                              readonly={readonly}
                              adjusted={adjusted}
                              adjustAvailable={adjustAvailable}
            ></Questions>
        if (data.type === Type.SPECIAL)
            return <ToDo item={data}
                         index={index}
                         key={data.id}
                         onChange={changeItem}
                         onChangeHeader={changeHeader}
                         actionFn={actionFn}
                         readonly={readonly}
            ></ToDo>
        return <div key={data.id}>Unrecognized widget type: {data.type}</div>
    }

    function onChangeSection(newModel) {
        setModel(newModel);
        onChange(newModel);
    }

    const handleAddWidget = (e) => {
        console.log('click', e);
    };


    function renderWidgetOperations(list, addItem) {
        if (!isMoreWidgetsAllowed() && readonly) {
            return <></>
        }
        return <Space wrap>
            <Button onClick={() => addItem(createToDo)} id="add-todo"><PlusOutlined
                className="light-button"/>To-Do</Button>
            <Button onClick={() => addItem(createQuestion)} id="add-question"><PlusOutlined
                className="light-button"/>Rules</Button>
            <Button onClick={() => addItem(createSpecial)} id="add-special" disabled={hasSpecialWidget}><PlusOutlined
                className="light-button"/><BulbOutlined/>Today's Special</Button>
        </Space>
    }

    //console.log('Editor: re-render', energyLevel, adjusted)
    return (<div>
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <List items={model}
                  render={renderWidget}
                  readonly={readonly}
                  onChange={onChangeSection}
                  operations={renderWidgetOperations}
            ></List>
        </Space>

    </div>)
}


