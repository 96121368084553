import React, {useState} from "react";
import ListItem from "./ListItem";

const List = ({items, readonly, render, onChange, operations}) => {

    const [listItems, setListItems] = useState(items);

    const addItem = (createItem) => {
        const item = createItem();
        let newList = [...listItems, item];
        setListItems(newList);
        onChange(newList)
    };

    const removeItem = (index) => {
        let newList = listItems.filter((_, i) => i !== index);
        setListItems(newList);
        onChange(newList)
    };

    const moveToTop = (index) => {
        const newList = [...listItems];
        const item = newList.splice(index, 1)[0];
        newList.unshift(item);
        setListItems(newList);
        onChange(newList);
    };

    const moveItemUp = (index) => {
        const newList = [...listItems];
        [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
        setListItems(newList);
        onChange(newList);
    };

    const actionsFn = (name, index) => {
        console.log('actionsFn', name, index, listItems)
        if (name === 'remove') {
            removeItem(index)
        } else if (name === 'moveUp') {
            moveItemUp(index)
        } else if (name === 'moveToTop') {
            moveToTop(index)
        } else if (name === 'add') {
            addItem()
        }
    }

    return (
        <>{listItems.map((item, index) => (
            <ListItem
                key={item.id}
                data={item}
                index={index}
                render={render}
                readonly={readonly}
                actionsFn={actionsFn}
            />
        ))}
            <div style={{marginTop: "1em"}}>{!readonly && operations && operations(listItems, addItem)}</div>
        </>
    );
};

export default List;
