import React from 'react';
import {Tooltip} from 'antd';

const MoodEmoji = ({text, hint}) => {

    const getColor = mood => {
        switch (mood) {
            case 'good':
                return 'green';
            case 'not-so-good':
                return 'orange';
            case 'disastrous':
                return 'red';
            default:
                return 'grey'; // Default color for no entry
        }
    };

    function decodeTextIntoMood(text) {
        // Define positive and negative words
        const positiveWords = ["happy", "joyful", "excited", "awesome", "good", "wonderful", "adore", "wow", "great"];
        const negativeWords = ["sad", "unhappy", "disappointed", "tired"];

        // Convert text to lowercase for case-insensitive matching
        const lowercasedText = text.toLowerCase();


        // Check for the presence of positive and negative words in the text
        const hasPositiveWord = positiveWords.some(word => lowercasedText.includes(word));
        const hasNegativeWord = negativeWords.some(word => lowercasedText.includes(word));

        // Determine mood based on the presence of positive and negative words
        let mood = "neutral";
        if (hasPositiveWord) {
            mood = "good";
        } else if (hasNegativeWord && hasPositiveWord) {
            mood = "not-so-good";
        } else if (hasNegativeWord && !hasPositiveWord) {
            mood = "disastrous";
        }
        console.log(mood, text)
        return mood;
    }


    function getEmoji(mood) {
        switch (mood) {
            case 'good':
                return '😀';
            case 'not-so-good':
                return '😥';
            case 'disastrous':
                return '😞';
            default:
                return '😐';
        }
    }

    // Creating a grid of days with colors based on mood
    return (
        <>
            {hint && <Tooltip title={<div>{text}</div>}>
                <div style={{fontSize: "larger", display: "inline-block"}}>{getEmoji(decodeTextIntoMood(text))}</div>
            </Tooltip>}
            {!hint &&
                <div style={{fontSize: "larger", display: "inline-block"}}>{getEmoji(decodeTextIntoMood(text))}</div>}
        </>
    );
};

export default MoodEmoji;
