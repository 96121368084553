import React, {useState} from "react";
import {Col, Row} from "antd";
import List from "../../utils/List";
import ToDoCheckbox from "./ToDoCheckbox";
import ToDoText from "./ToDoText";
import SectionHeader from "../../SectionHeader";
import {PlusOutlined} from "@ant-design/icons";
import {generateSecureId} from "../../data/permissions";

export function parseToDo(text) {
    if (text === undefined) {
        return undefined;
    }
    const todo = {};
    todo.done = text.startsWith("- [X]");
    todo.label = text.substring("- [X]".length + 1)
    return todo;
}

export function toText(td) {
    return `- [${td.done ? 'X' : ' '}] ${td.label}`
}

export default function ToDo({
                                 item,
                                 index,
                                 onChange,
                                 onChangeHeader,
                                 readonly,
                                 actionFn,
                                 adjustAvailable,
                             }) {

    const [todos, setTodos] = useState(item.list)                  // [{done:, label:}]
    const [header, setHeader] = useState(item.header)
    
    const onChangeH = text => {
        setHeader(text);
        onChangeHeader(item, text, index);
    }


    function ensureAllToDo() {
        let done = 0;
        todos.forEach(function (it) {
            if (it.done) {
                done++;
            }
        });
        return done === todos.length && todos.length > 0
    }

    function createToDo() {
        return {
            done: false,
            label: "one more todo item",
            id: generateSecureId(10)
        }
    }

    function renderToDoItem(data, i, actionsFn, readonly) {
        return <Row justify="start" align="middle" gutter={[8, 8]}>
            <Col span={2}>
                <ToDoCheckbox readonly={readonly} data={data} index={i} onChange={onChangeSingleToDo}/>
            </Col>
            <Col span={22}>
                <ToDoText readonly={readonly} data={data} index={i} onChange={onChangeSingleToDo}
                          actionsFn={actionsFn} adjustAvailable={adjustAvailable}/>
            </Col>
        </Row>
    }

    function addItemButton(list, addItem) {
        if (readonly) {
            return
        }
        return <PlusOutlined className="light-button top-right" onClick={() => addItem(createToDo)}/>
    }

    function onChangeToDo(list) {
        onChange(item, list, index);
    }

    function onChangeSingleToDo(todo, idx) {
        console.log('onChangeSingleToDo', todo, idx, item)
        const newTodos = todos.slice()
        newTodos[idx] = todo;
        setTodos(newTodos)
        onChangeToDo(newTodos);
    }

    return (
        <div className="widget todo">
            <SectionHeader readonly={readonly} onChange={onChangeH} index={index} item={item} header={header}
                           congrats={ensureAllToDo} actionsFn={actionFn}>
                <List items={todos}
                      render={renderToDoItem} congrats={ensureAllToDo}
                      readonly={readonly} createItem={createToDo} onChange={onChangeToDo}
                      operations={addItemButton}
                ></List>
            </SectionHeader>
        </div>
    )
}



