import React, {useEffect, useState} from "react";
import {Button, Space} from "antd";
import {updateProfile} from "./data/model";
import {PieChart} from "react-minimal-pie-chart";

const GUIDES_STEPS = [
    {
        anchor: "main-screen",
        isAvailable: function (profile, recentModel, historicalEntries) {
            const finishedSteps = profile.guides;
            return !finishedSteps || !finishedSteps.includes("main-screen");

        },
        description: "This is a default view which represents the current state of your day."

    },
    {
        anchor: "energy-widget",
        isAvailable: function (profile, recentModel, historicalEntries) {
            const finishedSteps = profile.guides;
            return !finishedSteps || !finishedSteps.includes("energy-widget");

        },
        description: <>
            <div>Enter how you are feeling; higher grade means higher level of fitness.
            </div>
            {/* <div>According to the values that you enter, I will change some of the parameters that you define. My goal
                is to help you manage risk by suggesting to reduce it on days that you're tired, and slightly increasing
                it when you're at your mental and physical best.
            </div>*/}
        </>

    },
    {
        anchor: "sos-button",
        isAvailable: function (profile, recentModel, historicalEntries) {
            const finishedSteps = profile.guides;
            return !finishedSteps || !finishedSteps.includes("sos-button");
        },
        description: <>
            <div>Hold <b>SOS</b> button for 2 minutes, breath slowly, calm down - this will prevent you from making
                mistakes.
            </div>
            {/* <div>According to the values that you enter, I will change some of the parameters that you define. My goal
                is to help you manage risk by suggesting to reduce it on days that you're tired, and slightly increasing
                it when you're at your mental and physical best.
            </div>*/}
        </>

    },
    {
        anchor: "previous-day",
        isAvailable: function (profile, recentModel, historicalEntries) {
            const finishedSteps = profile.guides;
            return finishedSteps === undefined || (!finishedSteps.includes("previous-day") && recentModel !== undefined);

        },
        description: <>
            <div><PieChart style={{width: "30px", height: "30px", marginBottom: "-0.4em"}}
                           lineWidth={50}
                           data={[
                               {
                                   title: 'Done',
                                   value: 80,
                                   color: 'green'
                               },
                               {title: 'Missed', value: 20, color: 'red'}
                           ]}
            /> This is the indicator of your discipline. I track your checks and answers. The previous day is always
                shown for the reference.
            </div>
        </>

    }
]

export function getSteps(profile, recentModel, historicalEntries) {
    console.log()
    return profile.noGuides ? [] : GUIDES_STEPS.filter(s => s.isAvailable(profile, recentModel, historicalEntries) === true)
}

function getStepByAnchor(anchor, steps) {
    if (!steps || steps.length === 0)
        return undefined;
    return steps.find(s => s.anchor === anchor)
}


const styles = {
    hintPosition: {
        position: 'absolute',
        cursor: 'pointer',
    },
    pulsingCircle: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        border: '3px solid red',
        animation: 'pulsate 2s ease-out infinite',
        boxSizing: 'content-box',
        fontSize: "14px",
        fontWeight: "normal",
    },
    dot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '12px',
        height: '12px',
        backgroundColor: 'red',
        borderRadius: '50%',
        fontSize: "14px",
        fontWeight: "normal",
    },
    hintBox: {
        position: 'absolute',
        background: '#f1f1f1',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        zIndex: 999999,
        fontSize: "14px",
        fontWeight: "normal",
    },
    closeButton: {
        position: 'absolute',
        right: '5px',
        top: '5px',
        cursor: 'pointer',
    },
    button: {
        margin: '5px',
        cursor: 'pointer',
    }
};

const Hint = ({anchor, steps, onClose, top, right, bottom, left, hintBoxStyle, profile, user, onNoGuides}) => {

    const [step, setStep] = useState(getStepByAnchor(anchor, steps));
    const [showHint, setShowHint] = useState(false);
    const [showDot, setShowDot] = useState(steps && steps.length > 0 && steps[0].anchor === anchor);
    const [showNext, setShowNext] = useState(steps.length > 1);

    // console.log("Hint, steps, step, showDot", steps, step, showDot)

    useEffect(() => {
        setShowDot(steps && steps.length > 0 && steps[0].anchor === anchor);
    }, [steps]);

    const handleClick = (e) => {
        e.stopPropagation();
        setShowHint(!showHint);
    };

    const handleNext = (event) => {
        handleClose();
    };

    const handleClose = () => {
        setShowHint(false);
        setShowDot(false);
        onClose && onClose();
    }

    const pulsingCircleStyle = showDot
        ? styles.pulsingCircle
        : {};


    const handleNoGuides = async () => {
        handleClose();
        const updated = profile;
        updated.noGuides = true;
        await updateProfile(user, updated);
        onNoGuides && onNoGuides();
    };

    return (
        <div
            style={{...styles.hintPosition, top: top, right: right, bottom: bottom, left: left}}
            onClick={handleClick}
        >
            <div style={pulsingCircleStyle}>
                {showDot && <div style={styles.dot}/>}
            </div>
            {showHint &&
                <div style={{...styles.hintBox, ...hintBoxStyle}}>
                    <div style={{paddingBottom: "1em"}}>
                        {step.description}
                    </div>
                    <div>
                        <Space>
                            {showNext && <Button type="primary" onClick={handleNext}>Next</Button>}
                            {!showNext && <Button type="primary" onClick={handleNext}>Got it!</Button>}
                            <Button type="link" onClick={() => handleNoGuides()}>Hide all hints!</Button>
                        </Space>
                    </div>
                </div>
            }
        </div>
    );
}

export default Hint;
