import React, {useEffect, useState} from 'react';
import {Typography} from 'antd';

const {Title} = Typography;

function Timer() {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return <Title level={3}>{seconds}s</Title>;
}

export default Timer;
