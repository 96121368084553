import React, {useState} from "react";
import SmartItem from "../../SmartItem";


const ToDoText = ({data, index, readonly, onChange, actionsFn, adjustAvailable}) => {

    const [label, setLabel] = useState(data.label);

    const onChangeLabel = (newLabel) => {
        setLabel(newLabel);
        const newData = data;
        newData.label = newLabel;
        onChange(newData, index);
    }


    return (
        <>
            <SmartItem value={label}
                       type="item"
                       readonly={readonly}
                       index={index}
                       onChangeItem={onChangeLabel}
                       actionsFn={actionsFn}
                       adjustAvailable={adjustAvailable}
            />
        </>
    )
};


export default ToDoText;