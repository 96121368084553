import React, {useEffect, useState} from "react";
import {Button, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {finishOnboarding, Focus, getProfile} from "./data/model";
import {useUserAuth} from "../context/UserAuthContext";
import moment from "moment/moment";

const Onboarding = ({dialogMode, onFinish, onReplace}) => {
    const {logOut, user} = useUserAuth();
    const [step, setStep] = useState(1);
    const [dataLoaded, setDataLoaded] = useState();
    const [profile, setProfile] = useState();
    const [isTrader, setIsTrader] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user || user.displayName === undefined || user.uid === undefined || dataLoaded) {
            return
        }
        const todayInDbFormat = moment().format('YYYYMMDD');
        (async () => {
            let pr = await getProfile(user)
            setProfile(pr)
            setIsTrader(pr.focus === Focus.TRADING)
            setDataLoaded(true)
        })();

    }, [user, dataLoaded, profile]);

    const firstTimeOnboarding = profile && profile.onboarding === undefined
    const isTradingButtonSelected = firstTimeOnboarding || isTrader

    const setFocusTrading = () => {
        setIsTrader(true)
        setStep(2)
    };

    const setFocusGeneral = () => {
        setIsTrader(false)
        setStep(2)
    };

    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const onStart = async () => {
        await finishOnboarding(user, isTrader);
        if (!dialogMode) {
            navigate("/home");
        } else {
            onFinish();
            onReplace(isTrader);
        }
    }

    const onReplaceCurrent = async () => {
        await finishOnboarding(user, isTrader);
        if (!dialogMode) {
            navigate("/home");
        } else {
            onFinish();
            onReplace(isTrader);
        }
    }


    const Step1 = () => (
        <>
            <div>
                <h2>Hi, welcome to CAPS.</h2>
                <p>I’m your personal assistant, trainer, and mentor.</p>
                <p>My goal is to help your trading become more consistent, adaptable, profitable, and scalable; in one
                    word, CAPS.</p>
                <br/>
                <p>Not a trader? No problem, the skills you learn here help in many areas!</p>
            </div>
            <div>
                <Space>
                    <Button onClick={setFocusTrading} type={isTradingButtonSelected ? 'primary' : 'default'}>
                        Enhance Trading Skills
                    </Button>
                    <Button onClick={setFocusGeneral} type={isTradingButtonSelected ? 'default' : 'primary'}>
                        Improve Lifestyle Habits
                    </Button>
                </Space>
            </div>
        </>
    );

    const Step2 = () => (
        <>
            <div>
                <h2>Ready for a change? Let's</h2>
                <ul>
                    <li>Craft your personalized rules.</li>
                    <li>Stick to them with discipline.</li>
                    <li>Catch when you sidestep your own plans.</li>
                </ul>
                {isTrader &&
                    <div>
                        <p>Apart from that, I’ll help you only trade at times, when you’re at your best.</p>
                        <p>Time to say goodbye to haphazard trading!</p>
                    </div>
                }
                <div>
                    I'll provide you with a starter list of smart, science-backed rules. Try them, tweak them, make them
                    yours. So, shall we get started?
                </div>
            </div>
            <div style={{marginTop: "1em"}}>
                <Space>
                    <Button onClick={handlePrevStep} type="default">
                        Back
                    </Button>
                    {!dialogMode &&
                        <Button onClick={onStart} type="primary">
                            Let's start!
                        </Button>
                    }
                    {dialogMode &&
                        <Button onClick={onReplaceCurrent} type="primary">
                            Swap Today's List with a Recommended One
                        </Button>
                    }
                </Space>
            </div>
        </>
    );


    return (
        <div>
            {step === 1 && <Step1/>}
            {step === 2 && <Step2/>}
        </div>
    );
};

export default Onboarding;

