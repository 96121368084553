import React from "react";
import {CalendarOutlined, DownOutlined,} from '@ant-design/icons';
import {Col, Dropdown, Row} from "antd";
import {useUserAuth} from "../context/UserAuthContext";
import moment from "moment";
import StatusWidget from "./StatusWidget";
import {getFlatList, getSparkline, getStatusFromText} from "./data/model";
import EnergyLevelWidget from "./EnergyLevelWidget";
import Editor from "./Editor";
import MoodEmoji from "./MoodEmoji";


export default function JournalChooser({history, onSelectedItem}) {
    const {logOut, user} = useUserAuth();

    let items = [];

    if (history !== undefined && history.length > 0) {
        history.map(h => {
            items = [{
                key: h.date,
                label: <>
                    <div><StatusWidget status={getStatusFromText(h)}/><span
                        style={{
                            marginLeft: "0.5em",
                            width: "3em",
                            display: "inline-block"
                        }}>{moment(h.date, 'YYYYMMDD').format('MMM,D')}</span>
                        <span style={{paddingLeft: "1em"}}><MoodEmoji text={h.todayIs} hint={true}/></span>
                        <span style={{paddingLeft: "1em"}}>{getSparkline(getFlatList(h))}</span>
                    </div>
                </>,

            }, ...items]
        })
    }

    const onClick = ({key}) => {
        const tabData = history.filter(h => h.date === key)[0]
        onSelectedItem(key, {
            key: key,
            label: <><StatusWidget status={getStatusFromText(tabData)}/><span
                style={{marginLeft: "0.5em"}}>{moment(tabData.date, 'YYYYMMDD').format('MMM,D')}</span>
            </>,
            closable: true,
            children: <>
                <Row style={{marginBottom: "1em"}}>
                    <Col span={15}>
                        Today {tabData.todayIs}
                    </Col>
                    <Col span={9} style={{textAlign: "right"}}>
                        <EnergyLevelWidget score={tabData.energyLevel} readonly={true}/>
                    </Col>
                </Row>
                <Editor editorData={tabData.data} readonly={true} entry={tabData}></Editor>
            </>,
        })
    };

    return (
        <Dropdown menu={{items, onClick}} trigger={['click']} placement="bottomRight">
            <a onClick={(e) => e.preventDefault()} style={{color: "black"}}>
                <CalendarOutlined/>
                <DownOutlined style={{fontSize: "0.5em"}}/>
                &nbsp;
                &nbsp;
            </a>
        </Dropdown>
    )
}





