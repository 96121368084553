import React, {useState} from "react";
import {Col, Input, Row} from "antd";
import MoodEmoji from "../MoodEmoji";


const {TextArea} = Input;

export default function TodayIsWidget({text, onChange, readonly}) {

    const [todayIs, setTodayIs] = useState(text);
    const prompt = "is...";

    const onInput = event => {
        setTodayIs(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    }

    return (
        <>
            <Row>
                <Col span={23}>
                    <TextArea className="light today-input" value={todayIs} size="large" placeholder={prompt}
                              onChange={onInput}
                              autoSize={{minRows: 1, maxRows: 3}} prefix={<div>Today</div>}
                    />
                </Col>
                <Col span={1}>
                    {todayIs.length > 0 &&
                        <div style={{marginTop: "10px"}}><MoodEmoji text={todayIs} hint={false}/></div>}
                </Col>
            </Row>
        </>
    )
}
