import React, {useState} from "react";
import {Button, Checkbox, Col, InputNumber, Popover, Row, Slider, Space, Tag} from "antd";
import {checkPermissions} from "./data/permissions";

function getPrompt(score) {
    if (score === undefined || score === 0) {
        return "Energy level is blank. Enter here..."
    } else if (score < 60) {
        return "Energy level: " + score + ", minimize risks!";
    } else if (score < 85) {
        return "Energy level: " + score;
    } else {
        return "Energy level: " + score + ", high confidence!";
    }
}

export function getLevel(score) {
    if (score === undefined || score === 0) {
        return undefined;
    } else if (score < 60) {
        return "low";
    } else if (score < 85) {
        return "medium";
    } else {
        return "high";
    }
}

function getDescription(score) {
    if (score === undefined || score === 0) {
        return "Energy level wasn't set"
    } else if (score < 60) {
        return "Energy level: " + score + "";
    } else if (score < 85) {
        return "Energy level: " + score;
    } else {
        return "Energy level: " + score + "";
    }
}

function getColor(score) {
    if (score === undefined || score === 0) {
        return "orange"
    } else if (score < 60) {
        return "red";
    } else if (score < 85) {
        return "orange";
    } else {
        return "success";
    }
}

const marks = {
    0: {
        style: {
            color: "red",
        },
        label: 0,
    },
    60: {
        style: {
            color: "orange",
        },
        label: 60,
    },
    85: {
        style: {
            color: "green",
        },
        label: 85,
    },
    100: {
        style: {
            color: "green",
        },
        label: 100,
    },
};


export default function EnergyLevelWidget({score, onChangeScore, readonly, adjusted, user}) {
    
    const [todaysScore, setTodaysScore] = useState(score);
    const [sliderScore, setSliderScore] = useState(score);
    const [text, setText] = useState(readonly ? getDescription(score) : getPrompt(score));
    const [color, setColor] = useState(getColor(score));
    const [open, setOpen] = useState(false);
    const [adjustedValue, setAdjustedValue] = useState(adjusted);

    const adjustAvailable = user ? checkPermissions(["PM_INVITE_OTHERS"], user.permissions) : false;
    
    const setAndHide = () => {
        setOpen(false);
        setText(getPrompt(sliderScore))
        setColor(getColor(sliderScore))
        setTodaysScore(sliderScore)
        onChangeScore(sliderScore, adjustedValue ? adjustedValue : false)
    };

    const cancelAndHide = () => {
        setOpen(false)
        setAdjustedValue(adjusted)
        setSliderScore(todaysScore)
    };

    const handleOpenChange = (newOpen) => {
        if (readonly) {
            return;
        }
        setOpen(newOpen);
    };

    const onChangeAdjusted = (e) => {
        //console.log('onChangeAdjusted', e.target.checked)
        setAdjustedValue(e.target.checked);
    };


    function changeSliderScore(newValue) {
        setSliderScore(newValue)
    }


    const content = (
        <>
            <Row style={{width: "280px"}} className="my-other-step">
                <Col span={6}>
                    <InputNumber
                        min={0}
                        max={100}
                        style={{width: "4em"}}
                        step={1}
                        value={sliderScore}
                        onChange={changeSliderScore}
                    />
                </Col>
                <Col span={18}>
                    <Slider defaultValue={50} value={sliderScore} onChange={changeSliderScore} marks={marks}
                            style={{width: "200px"}}
                    />
                </Col>
            </Row>
            {adjustAvailable &&
                <Row style={{width: "280px"}}>
                    <Col span={24}>
                        <Checkbox checked={adjustedValue} disabled={readonly} onChange={onChangeAdjusted}></Checkbox>
                        <span style={{marginLeft: "1em"}}>Adjust parameters accordingly</span>
                    </Col>
                </Row>
            }
            <Row style={{width: "280px"}}>
                <Col span={24}>
                    <Space>
                        <Button onClick={setAndHide} type="primary">Set</Button>
                        <Button onClick={cancelAndHide}>Cancel</Button>
                    </Space>
                </Col>
            </Row>

        </>
    );
    return (
        <Popover content={content} title="Today's energy level" trigger="click"
                 placement="bottomLeft" open={open} onOpenChange={handleOpenChange}>
            <Tag className="el-tag" color={color}>{text}</Tag>
        </Popover>
    )
}
