import React from "react";
import {PieChart} from "react-minimal-pie-chart";

export default function StatusWidget({status}) {

    return (
        <>
            {/*{status && <div className="status-widget">
                {status.done === status.total && <Tag icon={<LikeOutlined/>} color="success" className="status-tag" >All done!</Tag>}
                {(status.done > 0 && status.missed > 0) &&
                    <Tag className="status-tag" icon={<ExclamationCircleOutlined/>} >{Math.round(status.done/status.total * 100)}%</Tag>}
                {(status.done === 0 && status.total > 0) &&
                    <Tag className="status-tag" color="error">Nothing done!</Tag>}
            </div>}*/}
            <PieChart style={{width: "20px", height: "20px", marginBottom: "-0.4em", marginLeft: "0.5em"}}
                      lineWidth={50}
                      data={[
                          {
                              title: status.done === status.total ? 'All done!' : 'Done: ' + Math.round(status.done / status.total * 100) + "%",
                              value: status.done,
                              color: '#E0EEE0'
                          },
                          {title: 'Missed', value: status.total - status.done, color: '#FFDAB9'}
                      ]}
            />
        </>
    )
}
