import {Navigate} from "react-router-dom";
import React from "react";
import AppHeader from "./AppHeader";
import {useUserAuth} from "../context/UserAuthContext";
import Onboarding from "./Onboarding";


const OnboardingPage = () => {
    const {logOut, user} = useUserAuth();


    if (!user || !user.displayName) {
        return <Navigate to="/"/>;
    }


    return (
        <>
            <div className="container">
                <AppHeader hideUser={true}/>
                <div className="main" style={{padding: "1em"}}>
                    <Onboarding></Onboarding>
                </div>
            </div>
        </>
    );
};

export default OnboardingPage;



