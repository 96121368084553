import React, {useState} from "react";
import {Checkbox} from "antd";


const ToDoCheckbox = ({data, index, readonly, onChange}) => {

    const [done, setDone] = useState(data.done);

    const onChangeDone = event => {
        setDone(event.target.checked);
        const newData = data;
        newData.done = event.target.checked;
        onChange(newData, index);
    }

    return (
        <Checkbox checked={done} disabled={readonly} onChange={onChangeDone}></Checkbox>
    )
};


export default ToDoCheckbox;