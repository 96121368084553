import React, {useState} from 'react';
import {Input, Space, Typography} from "antd";
import {CopyOutlined, MailOutlined, WhatsAppOutlined} from '@ant-design/icons';

const {TextArea} = Input;
const {Text} = Typography;


const MessageSender = ({initialText}) => {
    const [text, setText] = useState(initialText);

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleShareToTelegram = () => {
        const encodedText = encodeURIComponent(text);
        const url = `tg://msg_url?url=${encodedText}`;
        window.open(url, '_blank');
    };

    const handleShareToWhatsApp = () => {
        const encodedText = encodeURIComponent(text);
        const url = `https://api.whatsapp.com/send?text=${encodedText}`;
        window.open(url, '_blank');
    };

    const handleEmailTo = () => {
        const encodedText = encodeURIComponent(text);
        const subject = encodeURIComponent('Your email subject');
        const url = `mailto:?subject=${subject}&body=${encodedText}`;
        window.open(url, '_self');
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(text).then(
            () => {
                console.log('Text copied to clipboard successfully');
            },
            (err) => {
                console.error('Failed to copy text to clipboard:', err);
            }
        );
    };

    return (
        <>
            <div>
                <Space>
                    <Text>Invitation token: <span style={{
                        padding: "0.5em",
                        fontWeight: "bold",
                        borderStyle: "dashed",
                        borderColor: "gray"
                    }}>{initialText}</span></Text>
                    Share via:
                    <CopyOutlined onClick={handleCopyToClipboard} className="share-button"/>
                    <WhatsAppOutlined onClick={handleShareToWhatsApp} className="share-button"/>
                    <MailOutlined onClick={handleEmailTo} className="share-button"/>
                </Space>
            </div>
            <div style={{marginTop: "1em"}}>
                <Text style={{color: "grey"}}>This token should be entered during the registration phase by the person
                    you invited.</Text>
            </div>
        </>
    );
};

export default MessageSender;