export function extractParam(rule) {
    if (!rule) {
        return null;
    }

    const paramRegex = /\[(\d+)(,(\d+),(\d+))?]/; // Regex to match parameter ranges

    const match = rule.toString().match(/\d+/); // Find parameter value in rule
    if (!match) {
        // If no parameter found, return null
        return null;
    }

    const [value] = match; // Extract parameter value
    const rangeMatch = rule.match(paramRegex); // Find parameter range in rule
    if (rangeMatch) {
        const [, low, , normal, high] = rangeMatch;
        return {
            value: parseInt(value),
            range: {
                low: low ? parseInt(low) : null,
                normal: normal ? parseInt(normal) : null,
                high: high ? parseInt(high) : null
            }
        };
    } else {
        // If no parameter range found, return parameter value
        return {
            value: parseInt(value)
        };
    }
}


export function getTextByEnergyLevel(inputString, energyLevel) {
    const paramData = extractParam(inputString);

    if (!paramData || !paramData.range) {
        // If no parameter data or range found, return the original input string
        return inputString;
    }

    let energyValue;
    switch (energyLevel) {
        case 'low':
            energyValue = paramData.range.low;
            break;
        case 'medium':
            energyValue = paramData.range.normal;
            break;
        case 'high':
            energyValue = paramData.range.high;
            break;
        default:
            // If an unrecognized energy level is provided, return the original input string
            return inputString;
    }

    if (energyValue === null) {
        // If the corresponding energy value is not found, return the original input string
        return inputString;
    }

    // Replace the parameter range with the energy value and return the modified string
    return inputString.replace(/\[\d+(,\d+,\d+)?]/, energyValue);
}


export function stringToHash(string) {

    let hash = 0;
    if (string.length === 0) return hash;
    for (let i = 0; i < string.length; i++) {
        let char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return hash;
}