import {Navigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import moment from "moment";
import Editor from "./Editor";
import EnergyLevelWidget from "./EnergyLevelWidget";
import {
    createTodayJournalFromTemplateEntry,
    CURRENT_LA_VERSION,
    getProfile,
    getStatusFromText,
    retrieveDbData,
    saveToDb,
    updateProfile
} from "./data/model";

import {Col, Row, Tabs} from 'antd';
import AppHeader from "./AppHeader";
import StatusWidget from "./StatusWidget";
import JournalChooser from "./JournalChooser";
import {useUserAuth} from "../context/UserAuthContext";
import TodayIsWidget from "./widgets/TodayIsWidget";
import Hint, {getSteps} from "./Hint";
import {checkPermissions} from "./data/permissions";
import SosButton from "./SosButton";
import MoodEmoji from "./MoodEmoji";

const Home = () => {
    const {logOut, user} = useUserAuth();
    const [dataLoaded, setDataLoaded] = useState(false)
    const [activeKey, setActiveKey] = useState('today');
    const [tabsItems, setTabsItems] = useState(undefined)

    const [requiredLicenseAgreement, setRequiredLicenseAgreement] = useState(false)

    const [todayModel, setTodayModel] = useState()
    const [recentModel, setRecentModel] = useState()
    const [historicalEntries, setHistoricalEntries] = useState(undefined)

    const [profile, setProfile] = useState()

    const [steps, setSteps] = useState()


    useEffect(() => {
        if (!user || user.displayName === undefined || user.uid === undefined || dataLoaded) {
            return
        }
        const todayInDbFormat = moment().format('YYYYMMDD');
        (async () => {
            let models = await retrieveDbData(todayInDbFormat, user, saveToDb);
            setTodayModel(models.today)
            setRecentModel(models.recent)
            setHistoricalEntries(models.history)
            let profile = await getProfile(user);
            setProfile(profile)
            if (!profile.laVersion || profile.laVersion !== CURRENT_LA_VERSION) {
                console.log('License agreement should be accepted')
                setRequiredLicenseAgreement(true);
            }
            console.log('Data loading completed!')
            setDataLoaded(true)
            setSteps(getSteps(profile, models.recent, models.history))
        })();

    }, [user, dataLoaded, todayModel, recentModel, historicalEntries, profile]);

    if (!user || !user.displayName) {
        return <Navigate to="/"/>;
    }

    if (requiredLicenseAgreement) {
        return <Navigate to="/agreement"/>;
    }

    const onChangeDescription = text => {
        //console.log('onChangeDescription', text, todayModel)
        const updated = todayModel;
        updated.todayIs = text;
        setTodayModel(updated);
        saveToDb(updated);
    }


    const onChangeScore = (newScore, newAdjusted) => {
        console.log("addEnergyScore: ", newScore, newAdjusted)
        const updated = todayModel;
        updated.energyLevel = newScore;
        updated.adjusted = newAdjusted;
        setTodayModel(updated)
        setTabsItems(tabsItems)
        saveToDb(updated);
    }

    const onEditorChange = editorData => {
        //console.log("onEditorChange: ", editorData)
        const updated = todayModel;
        updated.v1 = editorData;
        //updated.data = editorData;
        setTodayModel(updated);
        setTabsItems(tabsItems)
        saveToDb(updated);
    }

    const addTab = (key, tab) => {
        const newPanes = [];
        newPanes.push(tab)
        newPanes.push(...tabsItems)
        setTabsItems(newPanes);
        setActiveKey(key);
    };

    const onNextHint = async (anchor) => {
        const updated = profile;
        updated.guides = updated.guides ? [anchor, ...updated.guides] : [anchor]
        setProfile(updated)
        setSteps(getSteps(updated, recentModel, historicalEntries))
        setTabsItems(tabsItems)
        await updateProfile(user, updated);
    }

    const handleNoGuides = async (anchor) => {
        setSteps(undefined)
        setTabsItems(tabsItems)
    }


    const operations = {
        left: (<JournalChooser onSelectedItem={addTab} history={historicalEntries}></JournalChooser>)
    };

    const onChangeTabs = (newActiveKey) => {
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey) => {
        const newPanes = tabsItems.filter((item) => item.key !== targetKey);
        setTabsItems(newPanes);
        setActiveKey('today');
    };

    const onEditTabs = (
        targetKey,
        action
    ) => {
        if (action === 'add') {

        } else {
            remove(targetKey);
        }
    };


    const onReplace = async (isTrader) => {
        const journal = createTodayJournalFromTemplateEntry(user, isTrader)
        setTodayModel(journal)
        await saveToDb(journal)
        window.location.reload(false);
    }

    function createTabs() {
        const name = (user.displayName.split(' '))[0];
        const items = []
        const recent = recentModel;
        const today = todayModel;
        if (recent !== undefined) {
            items.push({
                key: 'recent',
                label: <><StatusWidget status={getStatusFromText(recent)}/><span
                    style={{marginLeft: "0.5em"}}>{moment(recent.date).format("MMM,D")}</span></>,
                closable: false,
                children: <>
                    <Row style={{marginBottom: "1em"}}>
                        <Col span={15}>
                            <MoodEmoji text={recent.todayIs} hint={false}/> Today {recent.todayIs}
                        </Col>
                        <Col span={9} style={{textAlign: "right"}}>
                            <EnergyLevelWidget score={recent.energyLevel} readonly={true} adjusted={recent.adjusted}/>
                        </Col>
                    </Row>
                    <Editor editorData={recent.data} readonly={true} entry={recent} adjusted={recent.adjusted}
                            energyLevel={recent.energyLevel}></Editor>
                </>,
            })
        }
        items.push({
            key: 'today',
            label: <>Today: {moment().format('MMMM,Do')}</>,
            closable: false,
            children: <>
                <Row>
                    <Col span={15}>
                        <h1 className="name">Hi, {name}! {steps &&
                            <Hint
                                anchor="main-screen"
                                steps={steps}
                                onClose={() => onNextHint("main-screen")}
                                top="10px"
                                right="20px"
                                hintBoxStyle={{width: "300px", left: "-300px"}}
                                profile={profile}
                                user={user}
                                onNoGuides={() => handleNoGuides()}
                            />
                        }
                            {steps &&
                                <Hint
                                    anchor="previous-day"
                                    steps={steps}
                                    onClose={() => onNextHint("previous-day")}
                                    profile={profile}
                                    user={user}
                                    top="-52px"
                                    left="40px"
                                    hintBoxStyle={{width: "300px", left: "0px"}}
                                    onNoGuides={() => handleNoGuides()}
                                />
                            }</h1>
                    </Col>
                    <Col span={9} style={{textAlign: "right"}}>
                        {steps &&
                            <Hint
                                anchor="sos-button"
                                steps={steps}
                                onClose={() => onNextHint("sos-button")}
                                top="10px"
                                left="70px"
                                hintBoxStyle={{width: "300px", left: "-250px"}}
                                profile={profile}
                                user={user}
                                onNoGuides={() => handleNoGuides()}
                            />
                        }
                        <SosButton/>
                    </Col>
                </Row>
                <div style={{fontSize: "larger", paddingLeft: "0.5em", marginBottom: "0.5"}}>Today
                    <EnergyLevelWidget score={today.energyLevel} adjusted={today.adjusted}
                                       onChangeScore={onChangeScore} user={user}/>
                    {steps &&
                        <Hint
                            anchor="energy-widget"
                            steps={steps}
                            onClose={() => onNextHint("energy-widget")}
                            top="45px"
                            left="100px"
                            hintBoxStyle={{width: "300px", left: "-50px"}}
                            profile={profile}
                            user={user}
                            onNoGuides={() => handleNoGuides()}
                        />
                    }
                </div>
                <TodayIsWidget text={today.todayIs} onChange={onChangeDescription}/>
                <Editor editorData={today.data} energyLevel={today.energyLevel} adjusted={today.adjusted}
                        onChange={onEditorChange} entry={today}
                        adjustAvailable={user && checkPermissions(["PM_INVITE_OTHERS"], user.permissions) === true}></Editor>
                {/*<MoodHeatmap entries={historicalEntries} />*/}
            </>
        })
        return items;
    }

    if (dataLoaded && todayModel && !tabsItems) {
        const items = createTabs();
        setTabsItems(items)
    }

    return (
        <>
            <div className="container">
                <AppHeader profile={profile} onReplaceTodaysEntry={onReplace}/>
                <div className="main">
                    <Tabs defaultActiveKey="today" items={tabsItems} tabBarExtraContent={operations}
                          hideAdd
                          onChange={onChangeTabs}
                          activeKey={activeKey}
                          onEdit={onEditTabs}
                          type="editable-card"/>
                </div>
            </div>
        </>
    );
};

export default Home;

