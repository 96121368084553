import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Input, Popover, Row, Space, Tag, Typography} from "antd";
import {extractParam} from "./data/parameters";

const {TextArea} = Input;
const {Text} = Typography;


export default function SmartItem({
                                      value,
                                      onChangeItem,
                                      transformFn,
                                      readonly,
                                      index,
                                      type,
                                      actionsFn,
                                      adjustAvailable,
                                  }) {

    const [text, setText] = useState(value);
    const [applyDisabled, setApplyDisabled] = useState(true);
    const [paramsInText, setParamsInText] = useState(extractParam(value));
    const initialValue = value;
    const [open, setOpen] = useState(false);
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (open && textAreaRef) {
            textAreaRef.current.resizableTextArea.textArea.focus();
            textAreaRef.current.resizableTextArea.textArea.select();
        }
    }, [open]);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        if (readonly) {
            return;
        }

        setOpen(newOpen);
    };

    function validate(text) {
        return text !== undefined && text.length > 0 && text.length < 256 && text !== value;
    }

    const onChangeText = event => {
        setText(event.target.value);
        setApplyDisabled(!validate(event.target.value))
        setParamsInText(extractParam(event.target.value))
    }

    const onCancel = () => {
        setText(initialValue);
        hide();
    }

    const onFinish = event => {
        event.preventDefault();
        onChangeItem(text);
        hide();
    }


    const onMoveUpAndHide = event => {
        event.preventDefault();
        hide();
        if (index > 0) {
            actionsFn('moveUp', index)
        }
    }

    const onMoveToTopAndHide = event => {
        event.preventDefault();
        hide();
        if (index > 0) {
            actionsFn('moveToTop', index)
        }
    }

    const onRemoveItem = event => {
        event.preventDefault();
        hide();
        actionsFn('remove', index)
    }

    function convertNumberIntoParameter() {
        const v = paramsInText.value;
        const newText = text.replace(v, `[${v},${v},${v}]`);
        setText(newText)
        setParamsInText(extractParam(newText))
    }

    const deleteButtonTitle = type === 'item' ? 'delete it' : 'delete the section'
    const deleteButton = <Button type="link" className="smart-item-control"
                                 onClick={onRemoveItem}>{deleteButtonTitle}</Button>
    const moveUp = <Button type="link" onClick={onMoveUpAndHide} className="smart-item-control">move up</Button>
    const moveToTop = <Button type="link" onClick={onMoveToTopAndHide} className="smart-item-control">move to
        top</Button>


    const title = index > 0 ? <>Edit {type}, {moveUp}, {moveToTop} or {deleteButton}</> : <>Edit {type} or {deleteButton}</>

    const controls = <>
        <Row justify="end">
            <Col span={11}>

            </Col>
            <Col span={13}>
                <Space style={{marginTop: "1em"}}>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" onClick={onFinish} disabled={applyDisabled}>Apply</Button>
                </Space>
            </Col>
        </Row>
    </>


    const editDialog = <div className="edit-item-dialog"><TextArea
        value={text}
        onChange={onChangeText}
        autoSize={{minRows: 1, maxRows: 3}}
        onPressEnter={(e) => e.preventDefault()}
        ref={textAreaRef}
    />
        {adjustAvailable && paramsInText && paramsInText.range &&
            <Row justify="start" align="middle" gutter={[8, 8]}>
                <Col span={6}>
                    Parameter:
                </Col>
                <Col span={18}>
                    {paramsInText.range && <><Tag color="red">low:{paramsInText.range.low}</Tag><Tag
                        color="gold">normal:{paramsInText.range.normal}</Tag><Tag
                        color="green">high:{paramsInText.range.high}</Tag></>}
                </Col>
            </Row>
        }
        {adjustAvailable && paramsInText && !paramsInText.range && paramsInText.value &&
            <Row justify="start" align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    Convert {paramsInText.value} into a parameter? <Button size="small"
                                                                           onClick={convertNumberIntoParameter}>Yes</Button>
                </Col>
            </Row>
        }
        {controls}
    </div>


    return (
        <>
            <Popover placement="bottomLeft" title={title} content={editDialog} trigger="click" open={open}
                     onOpenChange={handleOpenChange} destroyTooltipOnHide={true}>
                <Text className={type}
                      autoSize={{
                          minRows: 1,
                          maxRows: 3
                      }}>{transformFn ? transformFn(initialValue) : initialValue}</Text>
            </Popover>
        </>
    )
}



