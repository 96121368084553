import React, {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {useUserAuth} from "../context/UserAuthContext";
import {getAuth} from "firebase/auth";
import AppHeader from "./AppHeader";
import {GoogleOutlined,} from '@ant-design/icons';
import {Button, Col, Input, Row, Typography} from "antd";

const {Title} = Typography;


const Login = () => {
    const [token, setToken] = useState();
    const {googleSignIn, tokenRequired, tokenVerification, error, tmpUser} = useUserAuth();
    const navigate = useNavigate();
    const {logOut, user} = useUserAuth();

    const auth = getAuth();

    if (user && user.displayName) {
        return <Navigate to="/home"/>;
    }

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            await googleSignIn();
            navigate("/home");
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleTokenVerification = async (e) => {
        e.preventDefault();
        try {
            await tokenVerification(token);
            navigate("/home");
        } catch (error) {
            console.log(error.message);
        }
    };


    return (
        <>
            <div className="container">
                <AppHeader hideUser={true} profile={{}}/>
                <div className="main">
                    <Row>
                        <Col span={24} className="welcome">
                            <Title level={4}>Build your</Title>
                            <Title level={3} style={{marginTop: "0"}}><b className="highlight">C</b>onsistency, <b
                                className="highlight">A</b>daptability, <b className="highlight">P</b>rofitability, <b
                                className="highlight">S</b>calability</Title>
                            <Title level={4} style={{marginTop: "0"}}>with <b
                                className="highlight">CAPS</b> journal!</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="welcome">
                            {!tokenRequired &&
                                <Button
                                    type="primary"
                                    icon={<GoogleOutlined/>}
                                    onClick={handleGoogleSignIn}>
                                    Login with Google
                                </Button>
                            }
                            {!tmpUser && <div className="big-logo"></div>}
                        </Col>
                    </Row>
                    {tmpUser && <Row>
                        <Col span={24} className="welcome">
                            <div style={{marginBottom: "0.5em"}}>Hi, {tmpUser.displayName.split(' ')[0]}, please
                                provide <b>token</b> from the invitation:
                            </div>
                            {tokenRequired && <Input value={token} onChange={(e) => setToken(e.target.value)}
                                                     placeholder="Token" style={{width: "8em"}}/>}
                            <Button
                                type="primary"
                                style={{marginLeft: "0.5em"}}
                                onClick={handleTokenVerification}>
                                Go
                            </Button> or <Button style={{display: "contents", margin: 0}} onClick={logOut} type="link">sign
                            out</Button>
                            <div style={{marginTop: "0.5em"}}>{error &&
                                <span style={{color: "red", background: "white"}}>{error}</span>}</div>
                            <div className="big-logo"></div>
                        </Col>
                    </Row>
                    }
                </div>
            </div>
        </>
    );
};

export default Login;


