// withAuthorization.js
import React from 'react';
import {checkPermissions} from "../data/permissions";


const withAuthorization = (WrappedComponent, requiredPermissions, message) => {

    return class AuthorizedComponent extends React.Component {
        checkPermissions(userPermissions) {
            return checkPermissions(requiredPermissions, userPermissions);
        }

        render() {
            const {user} = this.props;
            if (!user || !this.checkPermissions(user.permissions)) {
                return <div>{message ? message : "You do not have permission to access this feature."}</div>;
            }

            return <WrappedComponent {...this.props} />;
        }
    };
};

export default withAuthorization;
