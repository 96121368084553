import {doc, setDoc} from "firebase/firestore";
import {firestore} from "../../firebase-config";
import moment from "moment";
import {getProfile} from "./model";


export const Permissions = {
    PM_INVITE_OTHERS: 'PM_INVITE_OTHERS',
    PM_USE_BIO: 'PM_USE_BIO',
};

function getPermissionsByRole(role) {
    if (role === 'ADMIN') {
        return ['PM_INVITE_OTHERS', 'PM_USE_BIO']
    } else if (role === 'ADVANCED') {
        return ['PM_USE_BIO']
    } else {
        return [];
    }
}

export async function retrieveUserWithPermissions(user) {
    console.log('retrieveUserWithPermissions', user);
    let profile = await getProfile(user);
    console.log('retrieveUserWithPermissions.profile', profile);
    if (profile === undefined) {
        return undefined;
    }
    const role = profile.role;
    profile.permissions = getPermissionsByRole(role);
    return profile;
}


export async function generateInvitation(user, general, email) {
    const token = generateSecureId(6)
    if (general) {
        const invite = {
            status: "pending",
            updated: moment.utc().format(),
            referral: user.uid,
            token: token
        }
        const id = generateSecureId()
        return setDoc(doc(firestore, "invitations", id), invite)
            .then(function (docRef) {
                return token;
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
                return undefined;
            });
    } else {
        //check permission
        //checkPermissions([Permissions.PM_INVITE_OTHERS], user.permissions)
        //insert into invitations table an invitation for a certain email
        const invite = {
            email: email,
            status: "pending",
            updated: moment.utc().format(),
            referral: user.uid,
            token: token
        }
        const id = generateSecureId()
        return setDoc(doc(firestore, "invitations", id), invite)
            .then(function (docRef) {
                return token;
            })
            .catch(function (error) {
                return undefined;
            });
    }
}

export function checkPermissions(requiredPermissions, userPermissions) {
    return requiredPermissions && userPermissions && requiredPermissions.every(permission =>
        userPermissions.includes(permission)
    );
}


export function generateSecureId(length = 20) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const bytes = new Uint8Array(length);
    window.crypto.getRandomValues(bytes);
    const result = [];

    for (let i = 0; i < length; i++) {
        const index = bytes[i] % characters.length;
        result.push(characters[index]);
    }

    return result.join('');
}